<template>
  <div class="scheme-contain">
    <div class="data-contain">
      <div class="card-wrapper">
        <div class="monitor-search">
          <div class="monitor-date">
            <Date-picker format="yyyy-MM-dd" type="daterange" placeholder="选择日期" v-model="foodDate" @on-change="changeDate"></Date-picker>
          </div>
          <div class="tab-contain">
            <router-link class="static-button" :to="{name: 'FoodTable'}">数据显示</router-link>
            <router-link class="chart-button" :to="{name: 'FoodChart'}">图表显示</router-link>
          </div>
        </div>
      </div>

      <div class="table-box">
        <div class="chart" ref="foodChart"></div>
      </div>

    </div>
  </div>
</template>
<script type='text/ecmascript-6'>
import echarts from 'echarts';
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			index: 2,
			sizePerpage: 10,
			foodDate: [],
			foodDateList: [],
			foodCalChart: '',
			foodWeightChart: '',
			charts: '',
			foodCalList: [],
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
		curPage() {
			return this.$route.params.page;
		},
	},
	created() {
		this.queryFoodRecode();
	},

	methods: {
		queryFoodRecode() {
			var req = {
				member_id: this.memberId,
				index: this.index,
				start: this.foodDate[0],
				end: this.foodDate[1],
				page: this.curPage,
				size: this.sizePerpage,
			};
			this.foodDateList = [];
			this.foodCalList = [];
			MemberService.queryFood(req).then((data) => {
				data.forEach((item) => {
					this.foodDateList.unshift(item.create_time);
					this.foodCalList.unshift(item.total);
				});
				// console.log('---date list = ' + this.foodDateList)
				// console.log('---cal list = ' + this.foodCalList)
				this.loadChart();
			});
		},
		changeDate(date) {
			this.foodDate = date;
			this.queryFoodRecode();
		},
		drawChart() {
			// echarts实例化
			this.chart = echarts.init(this.$refs.foodChart);

			// 初始化实例
			var option = {
				color: ['#F1C171'],
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						// 坐标轴指示器，坐标轴触发有效
						type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
					},
				},
				dataZoom: [
					{
						show: true,
					},
					{
						type: 'inside',
					},
				],
				grid: {
					left: '3%',
					right: '4%',
					bottom: '80',
					containLabel: true,
				},
				xAxis: [
					{
						type: 'category',
						data: this.foodDateList,
						axisTick: {
							alignWithLabel: true,
						},
					},
				],
				yAxis: [
					{
						name: '(Kcal)',
						type: 'value',
					},
				],
				series: [
					{
						name: '总能量',
						type: 'bar',
						barWidth: '60%',
						data: this.foodCalList,
					},
				],
			};
			// return options
			this.chart.setOption(option);
		},
		loadChart() {
			this.chart.setOption({
				xAxis: [
					{
						data: this.foodDateList,
					},
				],
				series: [
					{
						data: this.foodCalList,
					},
				],
			});
		},
	},
	mounted() {
		this.drawChart();
	},
};
</script>
<style lang='css' scoped>
</style>
